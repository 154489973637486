import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react";
import Bannerimg from "../../../images/static/mob-img01.png";
import Bannerimg02 from "../../../images/static/mob-img02.png";
import BannerTab01 from "../../../images/static/tab-img01.png";
import BannerTab02 from "../../../images/static/tab-img02.png";
import BannerDesk01 from "../../../images/static/desk-img01.png";
import BannerDesk02 from "../../../images/static/desk-img02.png";
import BannerDesk03 from "../../../images/static/desk03.png";
import BannerDesk04 from "../../../images/static/desk04.png";
import { isMobile, isMobileTab } from "../../jsutility/index";
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap";
import StaticList from "./StaticList";
import { v4 as uuidv4 } from "uuid";
import {isTablet, isIPad13, isIOS13} from 'react-device-detect';
// Style
import "./StaticGallery.scss";
const StaticGallery = (props) => {
  const GalleryList = [
    {
      GalleryName: "Cannock",
      GalleryImg: Bannerimg,
      GalleryTabImg: BannerTab01,
      GalleryDeskImg: BannerDesk01,
      Address: "Staffordshire Regional Hub",
      Street: "18 Salter Street",
      Location: "Stafford",
      house: " ST16 2JU",
      tel: "01543 469966",
      video: "IdejM6wCkxA",
    },

    {
      GalleryName: "Oakengates",
      GalleryImg: Bannerimg02,
      GalleryTabImg: BannerTab02,
      GalleryDeskImg: BannerDesk02,
      Address: " 6 Oxford Street",
      Street: "Oakengates",
      Location: "Shropshire",
      house: " TF2 6AA",
      tel: "01952 620021",
      video: "V8ksSGxo6no",
    },
    {
      GalleryName: "Shrewsbury",
      GalleryImg: Bannerimg02,
      GalleryTabImg: BannerTab02,
      GalleryDeskImg: BannerDesk03,
      Address: "12 Shoplatch",
      Street: "Shrewsbury",
      Location: "Shropshire",
      house: "SY1 1HL",
      tel: "01743 357032 ",
      video: "IdejM6wCkxA",
    },
    {
      GalleryName: "Stafford",
      GalleryImg: Bannerimg02,
      GalleryTabImg: BannerTab02,
      GalleryDeskImg: BannerDesk04,
      Address: "18 Salter Street",
      Street: "Stafford",
      Location: "Staffordshire",
      house: " ST16 2JU",
      tel: "01785 255800",
      video: "V8ksSGxo6no",
    },
  ];

  let [reviewList, setCustomerReview] = useState([]);
  let intialNumberOfReview = 4;

  useEffect(() => {
    
    if (isMobile()){
      intialNumberOfReview = 2;
    }
    
    else if(isTablet || isIPad13 || isIOS13){
      intialNumberOfReview = 2
    }
  }, []);

  useEffect(() => {
    setCustomerReview(GalleryList.slice(0, intialNumberOfReview));
  }, []);

  return (
    <section className="static-gallery">
      <Container>
        <div className="gallery-wrapper">
          {reviewList.map((item, index) => (
            <StaticList
              GalleryDeskImg={item.GalleryDeskImg}
              GalleryTabImg={item.GalleryTabImg}
              GalleryImg={item.GalleryImg}
              GalleryName={item.GalleryName}
              Address={item.Address}
              Street={item.Street}
              Location={item.Location}
              house={item.house}
              tel={item.tel}
              unkey={uuidv4()}
              videoid={item.video}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};
export default StaticGallery;
