import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Pagination } from 'react-bootstrap';
import Agent01 from "../../../images/static/agent.png"
import Agent02 from "../../../images/static/agent02.png"



// Style
import './ContactUs.scss';

import { inViewOptions, containerVariants } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const ContactUs = (props) => {

    return (
        <>
            <InView {...inViewOptions}>
                {({ ref, inView }) => (
                    <motion.section
                        className="contact-page"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            <Row>
                                <Col>
                                    <div className="about-content">
                                        <span className="sm-content">
                                            SEND US YOUR CV
                                        </span>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc et diam lorem. Nunc et blandit urna, ac sollicitudin felis.</p>
                                    </div>
                                    <div className="our-info">
                                        <div className="send">
                                            <Form className="form-data">
                                                <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Christopher" />

                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Rowe" />
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control type="tel" placeholder=" 07891292834" />
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword">
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control type="email" placeholder="chrisr@starberry.tv" />
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword">
                                                    <Form.Label>Suitable Locations</Form.Label>
                                                    <Form.Control type="text" placeholder="Select Locations" />
                                                </Form.Group>

                                                <Form.Group controlId="formBasicPassword">
                                                    <Form.Label>Attach your CV</Form.Label>
                                                    <Form.Control type="text" placeholder="Click to attach your CV" />
                                                </Form.Group>

                                                <Form.Group controlId="exampleForm.ControlTextarea1" className="query">
                                                    <Form.Label >Message</Form.Label>
                                                    <Form.Control className="msg-area" as="textarea" rows={5} placeholder="Write your message here..." />
                                                </Form.Group>
                                            </Form>
                                            <p className="link">
                                                By clicking Submit, you agree to our <Link href="#">Terms & Conditions</Link> and <Link href="#">Privacy Policy.</Link>
                                    </p>

                                            <Button variant="primary" type="submit">
                                                Submit Request
                                    <Link htef="#" className="slide">
                                                    <i className="icon-arrow">
                                                    </i>
                                                </Link>
                                            </Button>
                                        </div>
                                        <div className="team-info">
                                            <h2>Speak with our team</h2>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit accumsan congue.</p>

                                            <div className="about-agent">
                                                <div className="agent-info">
                                                    <div className="agent-img">

                                                        <img src={Agent01} alt="client" />
                                                    </div>
                                                    <div className="agent-details">
                                                        <h3>Jacqui Perchard</h3>
                                                        <span className="profile">Sales Manager</span>
                                                        <a className="tel-no" href="tel:+4407891293203">07891 293 203</a>
                                                    </div>
                                                </div>
                                                <div className="agent-info">
                                                    <div className="agent-img">
                                                        <img src={Agent02} alt="client" />
                                                    </div>
                                                    <div className="agent-details">
                                                        <h3>Matthew Hill</h3>
                                                        <span className="profile">Lettings Manager</span>
                                                        <a className="tel-no" href="tel:+4407891 039 495">07891 039 495</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </motion.section>
                )}
            </InView>
            <div className="book-valuation d-md-none">
                <Link href="#" className="btn btn-primary">
                    Book a Valuation
                </Link>
            </div>
        </>
    )
}

export default ContactUs