import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Pagination } from 'react-bootstrap';
import Img01 from "../../../images/management/img01.png"
import Img02 from "../../../images/management/img02.png"
import Img03 from "../../../images/management/img03.png"


// Style
import './Management.scss';

import { inViewOptions, containerVariants } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const PropertyManagement = (props) => {

    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="property-management"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <Container>
                        <Row>

                            <Col md={12}>
                                <div className="about-content">
                                    <sm className="sm-content">PROPERTY MANAGEMENT WITH  DB ROBERTS</sm>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et diam lorem.
                                    Nunc et blandit urna, ac sollicitudin felis.
                                        </p>
                                </div>
                            </Col>
                            <Col md={12} lg="6">
                                <div className="management-info img-zoom">
                                    <picture>
                                        <source media="(min-width:992px)" srcSet={Img03} />
                                        <source media="(min-width:768px)" srcSet={Img02} />
                                        <img src={Img01} alt="managementnt-Img" />
                                    </picture>
                                </div>
                            </Col>
                            <Col md={12} lg={6}>
                                <div className="management-details">
                                    <h2>One point of contact</h2>
                                    <p>Often property management is an overlooked ‘add-on’ that agencies offer. Not at
                                    DB Roberts. We have designed our sservice from the ground up. Enjoy one property manager for your property. No being passed around the management team, our managers know your property inside out, can deal directly with issues
                                        as they arise and always keep you in the loop. A truly personal service.</p>
                                    <div className="btn-wrapper">
                                        <Link href="#" className="btn ">
                                            Contact our team
                                    </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </motion.section>
            )}
        </InView>
    )
}

export default PropertyManagement