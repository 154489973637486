import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react";
import PlayVideo from "../../Play/PlayVideo";

import { inViewOptions, containerVariants } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const StaticList = (props) => {

  const [isPlay, setPlay] = useState(false);

  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <div className="gallery-img img-zoom">
              <div className="gallery-pic">
                <picture>
                  <source media="(min-width:992px)" srcSet={props.GalleryDeskImg} />
                  <source media="(min-width:768px)" srcSet={props.GalleryTabImg} />
                  <img src={props.GalleryImg} alt="Banner-Img" />
                </picture>
                <div className="banner-btn">
                  <strong
                    className="video-btn"
                    onClick={(e) => {
                      setPlay(true);
                    }}
                  >
                    <i className="video-icon"></i>
                  </strong>
                </div>
              </div>
              <div className="gallery-content">
                <h2>{props.GalleryName}</h2>
                <span className="main-address">{props.Address}</span>
                <span className="street">{props.Street}</span>
                <span className="location">{props.Location}</span>
                <span className="house">{props.house}</span>
                <span className="tel ">
                  <a className="tel-no" href={`tel:+44${props.tel}`}>
                    {props.tel}
                  </a>
                </span>
                <Link href="#" className="slider">
                  <i className="icon-slide"></i>
                </Link>
              </div>
            </div>
          </motion.div>
        )}
      </InView>
      <PlayVideo
        key={props.unkey}
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={props.videoid}
        isAutoPlay={1}
      />
    </React.Fragment>
  );
};

export default StaticList;
