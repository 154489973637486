import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Fade } from 'react-bootstrap';
// Style
import './Feedback.scss';
import Feedback from "react-bootstrap/esm/Feedback";

import { inViewOptions, containerVariants, fadeInFromBottom } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const FeedBack = (props) => {


    const FeedBackList = [
        {
            FeedbackText: "Excellent service from the original valuation of the property for Probate purposes to the marketing and eventual sale. Highly recommended.",
            ClientName: " Erica Botfield",
            ClientTime: " 22 days ago",
            MoreText: ""
        },
        {
            FeedbackText: "Lisa Edwards was professional and knows the rental market well and was able to give good advice. She  responded promptly and efficiently keeping us well informed on progress...",
            ClientName: "Michael and Sharan Duckett",
            ClientTime: "1 month ago",
            MoreText: "More"
        },
    ];
    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="feedbacks"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <Container>
                        <motion.div variants={fadeInFromBottom}>
                            <Row>
                                    {FeedBackList.map((item, index) => (

                                        <Col sm={12} md="6" className="d-flex align-items-strach">
                                            <div className="feedback-content">
                                                <ul className="rating">
                                                    <li>
                                                        <Link>
                                                            <i className="icon-star">
                                                            </i>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link>
                                                            <i className="icon-star">
                                                            </i>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link>
                                                            <i className="icon-star">
                                                            </i>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link>
                                                            <i className="icon-star">
                                                            </i>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link>
                                                            <i className="icon-star">
                                                            </i>
                                                        </Link>
                                                    </li>
                                                </ul>
                                                <p>{item.FeedbackText} <span className="more_text">{item.MoreText}</span></p>
                                                <div className="about-client">
                                                    <span className="sm-text">
                                                        {item.ClientName}
                                                    </span>
                                                    <span className="time">
                                                        {item.ClientTime}
                                                    </span>
                                                </div>
                                            </div>

                                        </Col>

                                    ))}

                            </Row>
                        </motion.div>
                    </Container>
                </motion.section>
            )}
        </InView>
    )
}



export default FeedBack