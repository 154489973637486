import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Pagination } from 'react-bootstrap';
// Style
import './Pagination.scss';

import { inViewOptions, containerVariants } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const PaginationSection = (props) => {

    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="pagination-section"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <Container>
                        <Row>
                            <Col>
                                <Pagination>
                                    <Pagination.Prev className="prev"><i className="left-arrow"></i></Pagination.Prev>
                                    <Pagination.Item active>{1}</Pagination.Item>

                                    <Pagination.Item>{2}</Pagination.Item>
                                    <Pagination.Item>{3}</Pagination.Item>
                                    <Pagination.Item >{4}</Pagination.Item>
                                    <Pagination.Item>{5}</Pagination.Item>

                                    <Pagination.Next className="next"><i className="right-arrow"></i></Pagination.Next>
                                </Pagination>
                            </Col>
                        </Row>
                    </Container>
                </motion.section>
            )}
        </InView>
    )
}

export default PaginationSection