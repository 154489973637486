import React from "react";
import Header from '../../components/Header/Header'
import StaticBanner from '../../components/StaticPage/StaticBanner/StaticBanner'
import StaticGallery from '../../components/StaticPage/StaticGallery/StaticGallery'
import Feedbacks from '../../components/StaticPage/Feedback/Feedback'
import Pagination from '../../components/StaticPage/Pagination/Pagination'
import Agents from '../../components/StaticPage/Agents/Agents'
import OurClient from '../../components/StaticPage/OurClients/OurClients'
import  Magazine from '../../components/StaticPage/Magazine/Magazine'
import  Management from '../../components/StaticPage/PropertyManagement/Management'
import  Contact from '../../components/StaticPage/ContactUs/ContactUs'






import Footer from '../../components/Footer/Footer'
const StaticPage = () => {
    const [state, setState] = React.useState({
        showMenu: false
    })

    const handlerClick = () => {

  
        setState({...state,showMenu:!state.showMenu})
        var root = document.getElementsByTagName('html')[0];
        if (state.showMenu == true) {
            document.body.className = ''; 
            root.className = ''; 
        } else {
            document.body.classList.add("overflow-hidden");   
            root.classList.add("overflow-hidden");  
        }
   
}

return(
        <>
            <div className={state.showMenu ? "wrapper open-search-block" : "wrapper"}>
                 <Header showMenu={state.showMenu} handlerClick={handlerClick}/>
                <StaticBanner />
                <StaticGallery />
                <Feedbacks/>
                <Pagination/>
                <Agents/>
                <OurClient/>
                <Magazine/>
                <Management/>
                <Contact/>
                <Footer />
            </div>
        </>
    )
}

export default StaticPage;